<template>
  <div v-if="statement" class="ml-2 mr-1">
  <b-card>
    <b-col>
      适用法律：
      <b-badge
        v-for="(lawItem, lawIdx) in aryCompliance"
        :key="`lay-${lawIdx}`"
        variant="light-primary"
        class="mr-1"
      >
        <b-icon-shield-lock /> <span>{{ lawItem }}</span>
      </b-badge>
    </b-col>

    <b-col class="my-1"> 编号：{{ statement.issueNo }} </b-col>

    <b-col class="my-1"> 主题：{{ statement.title }} </b-col>

    <b-col class="my-1"> 概述：{{ statement.abstract }} </b-col>

    <b-col class="my-1" v-if="statement.displayFile">
      
      <pdf
        v-for="i in numPages"
        :src="statement.displayFile"
        :page="i"
        :key="i"
        class="d-inline-block w-100"
      />
      <!-- <object :data="statement.displayFile" type="application/pdf" width="100%" height="600px">
          <p>Alternative text - include a link <a :href="statement.displayFile">to the PDF!</a></p>
        </object> -->
    </b-col>
  </b-card>
  </div>
</template>

<script>
import {
  BBadge,
  BCard,
  BIconShieldLock,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckboxGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BLink,
  BProgress,
} from "bootstrap-vue";

import pdf from "vue-pdf";

export default {
  name: "StatementView",

  components: {
    BBadge,
    BCard,
    BIconShieldLock,
    BCol,
    BFormGroup,
    BFormCheckboxGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BRow,
    BLink,
    BProgress,
    pdf,
  },

  data() {
    return {
      statement: null,
      aryCompliance: [],

      numPages: 0,
      loadingPdfTask: null,
    };
  },

  beforeCreate() {
    let { id } = this.$route.params;
    this.$http.get("/privacystatements/" + id).then((res) => {
      this.statement = res.data.data;
      this.aryCompliance = this.statement.compliance
        ? this.statement.compliance.split(",")
        : [];

        
        if (this.statement.displayFile) {
          this.loadingPdfTask = pdf.createLoadingTask(this.statement.displayFile);
          this.loadingPdfTask.promise.then((pdf) => {
            this.numPages = pdf.numPages;
          });
        }
    });
  },

  computed: {},

  methods: {},
};
</script>

<style>
</style>